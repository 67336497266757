<template>
  <div>
    <template v-if="loading">
      <div class="spinner-container">
        <AppSpinner
          size="small"
          class="spinner"
        />
      </div>
    </template>
    <template v-else>
      <!---->
      <!-- not loading -->
      <!---->
      <template v-if="serverErrorFetching">
        <!---->
        <!-- server error -->
        <!---->
        <p>{{ serverErrorFetching }}</p>
        <p>{{ serverErrorFetchingDetails }}</p>
      </template>
      <template v-else>
        <!---->
        <!-- no server error -->
        <!---->
        <div class="scrollable">
          <table
            class=""
          >
            <thead>
              <tr>
                <th>
                  Date
                </th>
                <th class="pl-gap-0_62x">
                  Step
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(entry, index) in data"
                :key="`history-${index}`"
              >
                <td class="p-0">
                  {{ translationStore.getLocalFormattedDate(entry.inserted_at, 'Ppp') }}
                </td>
                <td class="p-0 pl-gap-0_62x">
                  {{ entry.onboarding_step }}
                </td>
                <td class="p-0 pl-gap-0_62x">
                  {{ translationStore.t(`${translationPrefix}.${allStepsById[entry.onboarding_step].phaseKey}.${allStepsById[entry.onboarding_step].stepKey}`) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </template>
  </div>
</template>

<script
  setup
  lang="ts"
>
import { type PropType, inject, onMounted, ref } from 'vue';
import { AxiosError, type AxiosStatic } from 'axios';

import { useTranslationStore } from '@/stores/translation';

import AppSpinner from '@/components/misc/AppSpinner.vue';

const props = defineProps({
  userId: {
    type: Number,
    required: true,
  },
  userJourneyPagesFiltered: {
    type: Object as PropType<{
      PHASE_1: {
        [key: string]: number;
      };
      PHASE_2: {
        [key: string]: number;
      };
      PHASE_3: {
        [key: string]: number;
      };
      PHASE_4: {
        [key: string]: number;
      };
    }>,
    required: true,
  },
  translationPrefix: {
    type: String,
    required: true,
  },
});

const $log: any = inject('$log');
const $http: undefined | AxiosStatic = inject('$http');
const translationStore = useTranslationStore();

const loading = ref(false);
const serverErrorFetching = ref('');
const serverErrorFetchingDetails = ref('');
type UserHistory = {
  inserted_at: string;
  onboarding_step: number;
  user_id: number;
}[];
const data = ref<UserHistory>([]);
const allStepsById = ref<{
  [key: string]: { // e.g. key: 1001
    phaseKey: string; // e.g. phaseKey: PHASE_1
    stepKey: string; // e.g. stepKey: P1_HELLO_START_USING
  };
}>({});

function setAllStepsById() {
  console.log(props.userJourneyPagesFiltered);

  Object.entries(props.userJourneyPagesFiltered).forEach(([phaseKey, phaseSteps]) => {
    console.log('phase key:', phaseKey);
    Object.entries(phaseSteps).forEach(([stepKey, stepId]) => {
      allStepsById.value[stepId] = { phaseKey, stepKey };
    });
  });
}

async function fetchHistory() {
  try {
    loading.value = true;

    interface HistoryResponse {
      data: {
        data: UserHistory;
      };
    }

    const response: undefined | HistoryResponse = await $http?.get(`/users/${props.userId}/onboarding_history`);
    console.log(response);
    if (response && response.data && response.data.data) {
      data.value = response.data.data
        .sort((a, b) => new Date(a.inserted_at).getTime() - new Date(b.inserted_at).getTime()); // a - b ASC // difference between dates is the milliseconds value between them, but use getTime() for type safety
    } else {
      // No response.data
      $log.error('fetchHistory: no response data?', response);
    }
  } catch (error) {
    serverErrorFetching.value = 'There was an error fetching the GetReady Program history.';
    serverErrorFetchingDetails.value = (error instanceof AxiosError && error.message) || '';
    $log.error(`There was an error fetching the GetReady Program history: ${error}`);
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  setAllStepsById();
  fetchHistory();
});
</script>

<style
  lang="scss"
  scoped
>
.scrollable {
  height: 70vh;
  overflow-y: scroll;
}
</style>
